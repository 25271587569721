import { ethers } from "ethers"
import { useState, useEffect } from "react"
import { toast } from "react-toastify"
import { CONTRACTS, useWeb3 } from "./useContract"
import { useRefresh } from "./useRefresh"

export const useMetamask = () => {
  const web3 = useWeb3()
  const { shouldRefresh } = useRefresh(1000)
  const [isMetamask, setIsMetamask] = useState(false)

  useEffect(() => {
    if (!web3 || !(web3 instanceof ethers.providers.Web3Provider)) {
      return
    }

    setIsMetamask(web3.provider.isMetaMask ?? false)
  }, [web3.connection.url, shouldRefresh])

  const addWesenditToken = async () => {
    if (!web3 || !(web3 instanceof ethers.providers.Web3Provider) || !web3.provider.request) {
      return
    }

    const addRequest = web3.provider.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: CONTRACTS.WSI_TOKEN_ADDRESS,
          symbol: 'WSI',
          decimals: 18,
          image: 'https://app.wesendit.io/assets/img/logo-wesendit.svg'
        }
      } as any
    })

    await toast.promise(addRequest, {
      pending: 'Adding $WSI token...',
      success: 'Successfully added $WSI token',
      error: 'Failed to add $WSI token'
    })
  }

  return {
    isMetamask,
    addWesenditToken
  }
}
