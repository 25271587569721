import moment from "moment"
import { useEffect, useState } from "react"
require('moment-duration-format')

export const useCountdown = (start: number, end: number) => {
  const [countdown, setCountdown] = useState<[string, string, string, string]>(['', '', '', ''])
  const [isBeforeStart, setIsBeforeStart] = useState(false)
  const [isBeforeEnd, setIsBeforeEnd] = useState(false)

  const calulateCountdown = () => {
    const startTimestamp = moment.unix(start)
    const endTimestamp = moment.unix(end)

    const isBeforeStart = moment().isBefore(startTimestamp)
    const isBeforeEnd = moment().isBefore(endTimestamp)

    setIsBeforeStart(isBeforeStart)
    setIsBeforeEnd(isBeforeEnd)

    const duration = isBeforeStart ?
      (start - moment().unix()) :
      (isBeforeEnd ? moment().unix() - end : 0)

    return (moment.duration(Math.abs(duration), 'seconds') as any).format('DD:HH:mm:ss', { trim: false }).split(':')
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(calulateCountdown())
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [start, end])

  return {
    tiles: countdown,
    isBeforeStart,
    isBeforeEnd
  }
}