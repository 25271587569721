import classNames from "classnames"

interface Props {
  classnames?: string,
  children: any,
  noPaddingY?: boolean,
  noPaddingX?: boolean
  title?: string
  noGap?: boolean
}

const Card = (props: Props) => {
  const { children, noPaddingX, noPaddingY, classnames, title, noGap } = props

  return (
    <div className={classNames(
      'bg-light-blue rounded-3xl flex flex-col justify-center items-center w-full text-center',
      {
        'px-8 lg:px-12': !noPaddingX,
        'py-4 lg:py-8': !noPaddingY,
        'gap-6': !noGap
      },
      classnames
    )}>
      {title && <span className='text-2xl lg:text-[26px] font-light text-primary self-center'>{title}</span>}
      {children}
    </div>
  )
}

export default Card