'use client'

import { useTranslation } from "../app/i18n/client"
import { TileProps } from "./Tile"
import classNames from "classnames"

export enum CountdownTileMode {
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes',
  SECONDS = 'seconds'
}

interface Props extends Omit<TileProps, 'label'> {
  value: string
  mode: CountdownTileMode
}

const CountdownTile = (props: Props) => {
  const { t } = useTranslation(undefined, 'common')
  const { mode } = props

  return <div className={classNames(
    'flex flex-col gap-2 justify-center h-max items-center w-24 bg-transparent border-2 border-primary rounded-2xl !text-primary font-light pt-3 pb-2'
  )}>
    {props.value.length > 0 ?
      <span className='text-3xl lg:text-4xl break-all font-light'>{props.value}</span>
      :
      <div className='text-3xl lg:text-4xl break-all font-light before:content-["1"] before:invisible animate-pulse bg-slate-300 w-12 lg:w-16 rounded-xl'></div>
    }
    <span className='text-sm lg:text-base font-bold'>
      {t(`countdown.${mode}`)}
    </span>
  </div>
}

export default CountdownTile