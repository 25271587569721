import { BigNumber } from "ethers"
import { parseEther } from "ethers/lib/utils"
import { useState, useEffect } from "react"
import { useCrowdsaleContract, useAccount, useErc20TokenContract, CONTRACTS } from "./useContract"
import { useRefresh } from "./useRefresh"
import moment from 'moment'

export enum SourceType {
  BUSD = 'BUSD',
  BNB = 'BNB'
}

export const useCrowdsale = (crowdsaleAddress: string) => {
  const contract = useCrowdsaleContract(crowdsaleAddress)
  const {
    account,
    signer
  } = useAccount()
  const {
    doManualRefresh,
    shouldManualRefresh,
    shouldRefresh
  } = useRefresh(10000)
  const wsiToken = useErc20TokenContract(CONTRACTS.WSI_TOKEN_ADDRESS)

  const [progress, setProgress] = useState<number>(0)
  const [soldToken, setSoldToken] = useState<BigNumber>(BigNumber.from(0))
  const [wsiPrice, setWsiPrice] = useState<BigNumber>(BigNumber.from(1))
  const [busdPerBnb, setBusdPerBnb] = useState<BigNumber>(BigNumber.from(1))
  const [startTimestamp, setStartTimestamp] = useState<BigNumber>(BigNumber.from(0))
  const [endTimestamp, setEndTimestamp] = useState<BigNumber>(BigNumber.from(0))
  const [busdAddress, setBusdAddress] = useState('')
  const [usdtAddress, setUsdtAddress] = useState('')
  const [wsiBalance, setWsiBalance] = useState(BigNumber.from(0))
  const [isStarted, setIsStarted] = useState(false)

  useEffect(() => {
    getProgress().then(setProgress)
    getSoldToken().then(setSoldToken)
    getWsiPrice().then(setWsiPrice)
    getBusdForBnb(parseEther('1')).then(setBusdPerBnb)
    getStartTimestamp().then(setStartTimestamp)
    getEndTimestamp().then(setEndTimestamp)
    getBusdAddress().then(setBusdAddress)
    getUsdtAddress().then(setUsdtAddress)
    getWsiBalance().then(setWsiBalance)
  }, [shouldRefresh, shouldManualRefresh])

  useEffect(() => {
    const start = moment.unix(startTimestamp.toNumber())

    setIsStarted(
      moment().isAfter(start)
    )
  }, [startTimestamp, endTimestamp])

  const getBusdAddress = async () => {
    return await contract.busdAddress()
  }

  const getUsdtAddress = async () => {
    return await contract.usdtAddress()
  }

  const getBusdForBnb = async (amountBnb: BigNumber) => {
    const amounts = await contract.getBusdForBnb(amountBnb)

    return BigNumber.from(amounts[1])
  }

  const getWsiPrice = async () => {
    const result = await contract.price()

    return BigNumber.from(result)
  }

  const getWsiBalance = async () => {
    const result = await wsiToken.balanceOf(crowdsaleAddress)

    if (BigNumber.from(result).lte(parseEther('1'))) {
      return BigNumber.from(0)
    }

    return BigNumber.from(result)
  }

  const getStartTimestamp = async () => {
    return await contract.startTimestamp()
  }

  const getEndTimestamp = async () => {
    return await contract.endTimestamp()
  }

  const getSoldToken = async () => {
    const result = await contract.soldToken()

    if (BigNumber.from(result).gt(parseEther('37499999'))) {
      return parseEther('37500000')
    }

    return BigNumber.from(result)
  }

  const getProgress = async () => {
    return 1
  }

  const buyToken = async (sourceType: SourceType, sourceAmount: string) => {
    if (!signer) {
      throw new Error('Missing Signer')
    }

    const amount = parseEther(sourceAmount)

    if (sourceType === SourceType.BNB) {
      // Convert BNB to BUSD
      const amounts = await contract.getBusdForBnb(
        amount
      )
      const amountBusdRaw: BigNumber = amounts[1]
      const slippageAmount = amountBusdRaw.div(100).mul(5) // subtract 5% slippage
      const amountBusd = amountBusdRaw.sub(slippageAmount)

      const tx = await contract.connect(signer).buyTokenBNB(
        amountBusd,
        {
          value: amount,
          gasLimit: 3000000
        }
      )
      await tx.wait()
    } else if (sourceType === SourceType.BUSD) {
      const tx = await contract.connect(signer).buyTokenBUSD(
        amount,
        {
          gasLimit: 3000000
        }
      )
      await tx.wait()
    }

    doManualRefresh()
  }

  return {
    address: crowdsaleAddress,
    getBusdAddress,
    getUsdtAddress,
    getBusdForBnb,
    getStartTimestamp,
    getEndTimestamp,
    buyToken,
    progress,
    soldToken,
    doManualRefresh,
    wsiPrice,
    busdPerBnb,
    startTimestamp,
    endTimestamp,
    busdAddress,
    usdtAddress,
    wsiBalance,
    isStarted
  }
}