'use client'

import { MdAdd, MdArrowDropDown, MdArrowRight, MdExitToApp, MdOpenInNew } from 'react-icons/md'
import Card from '../../components/Card'
import Button, { ButtonSize } from '../../components/Button'
import { CONTRACTS, useAccount } from '../../hooks/useContract'
import { ChangeEvent, useEffect, useState } from 'react'
import { useCountdown } from '../../hooks/useCountdown'
import { formatEther, parseEther } from 'ethers/lib/utils'
import { toast } from 'react-toastify'
import { SourceType } from '../../hooks/useCrowdsale'
import { Trans } from 'react-i18next'
import { useTranslation } from '../i18n/client'
import LinkComponent from '../../components/LinkComponent'
import Layout from '../../components/Layout'
import { ERC20Token, MAX_UINT_256, useErc20Token } from '../../hooks/useErc20'
import Input from '../../components/Input'
import Tile from '../../components/Tile'
import { usePancakeRouter } from '../../hooks/usePancakeRouter'
import classNames from 'classnames'
import { EtherUtils } from '../../utils/ether.utils'
import { BigNumber } from 'ethers'
import { useMetamask } from '../../hooks/useMetamask'
import CountdownTile, { CountdownTileMode } from '../../components/CountdownTile'
import { useWeb3Modal, useDisconnect } from '@web3modal/ethers5/react'

interface TokenOption {
  label: string,
  value: SourceType,
  image: string
}

export default function Page() {
  const { t } = useTranslation(undefined, ['common', 'index']);
  const { open: doConnect } = useWeb3Modal()
  const { disconnect: doDisconnect } = useDisconnect()
  const {
    account,
    balance: ethBalance
  } = useAccount()
  const pancakeRouter = usePancakeRouter()
  const metamask = useMetamask()

  const [sourceType, setSourceType] = useState<SourceType>(SourceType.BNB)
  const [sourceInput, setSourceInput] = useState<string>('')
  const [destinationInput, setDestinationInput] = useState<string>('')

  const busdToken = useErc20Token(CONTRACTS.BUSD_TOKEN_ADDRESS)
  const countdown = useCountdown(1669921200, 1669921200)

  const [slippageInput, setSlippageInput] = useState<string>('1')

  const [isValidSource, setValidSource] = useState(true)
  const [isValidSlippage, setValidSlippage] = useState(true)

  const TOKEN_OPTIONS: TokenOption[] = [
    {
      label: 'BNB',
      value: SourceType.BNB,
      image: '/assets/img/bnb-logo.svg'
    },
    {
      label: 'BUSD',
      value: SourceType.BUSD,
      image: '/assets/img/busd-logo.svg'
    }
  ]

  useEffect(() => {
    calculateDestination(sourceInput, sourceType)
  }, [pancakeRouter.usdPrice])

  const getTokenForSourceType = (sourceType: SourceType): ERC20Token | undefined => {
    if (sourceType === SourceType.BUSD) {
      return busdToken
    }
  }

  const onInputSource = async (event: ChangeEvent<HTMLInputElement>) => {
    onChangeSource(event.target.value)
  }

  const onChangeSource = async (value: string) => {
    setSourceInput(value)
    validateSource(value, sourceType)
    calculateDestination(value, sourceType)
  }

  const onInputDestination = async (event: ChangeEvent<HTMLInputElement>) => {
    onChangeDestination(event.target.value)
  }

  const onChangeDestination = async (value: string) => {
    setDestinationInput(value)

    const sourceValue = await calculateSource(value)
    if (!sourceValue) {
      return
    }

    validateSource(sourceValue, sourceType)
  }

  const onClickBalance = () => {
    if (countdown.isBeforeStart) {
      return
    }

    onChangeSource(getBalance())
  }

  const onInputSlippage = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    if (value.length > 0 && !validateSlippage(value)) {
      return
    }

    setSlippageInput(value)
  }

  const validateSlippage = (value: string): boolean => {
    try {
      const num = parseFloat(value)

      if (num < 0 || num > 10) {
        toast.dismiss('slippage-frontrun')
        setValidSlippage(false)
        return false
      }

      if (num >= 6) {
        toast.warn(`Your transaction may be front run!`, {
          toastId: 'slippage-frontrun',
          position: 'bottom-right',
          autoClose: false
        })
      } else {
        toast.dismiss('slippage-frontrun')
      }

      setValidSlippage(true)
      return true
    } catch (err) {
      setValidSlippage(false)
      return false
    }
  }

  const getBalance = () => {
    let balance = BigNumber.from(0)

    if (sourceType === SourceType.BNB) {
      balance = ethBalance
    } else if (sourceType === SourceType.BUSD) {
      balance = busdToken.balance
    }

    return EtherUtils.formatEther(balance, 3)
  }

  const validateSource = (value: string, type: SourceType) => {
    try {
      const num = parseEther(value)

      if (num.eq(0)) {
        setValidSource(false)
        return
      }

      if (type === SourceType.BNB && num.gt(ethBalance)) {
        setValidSource(false)
        return
      }

      if (type === SourceType.BUSD && num.gt(busdToken.balance)) {
        setValidSource(false)
        return
      }

      setValidSource(true)
    } catch (err) {
      setValidSource(false)
    }
  }

  const calculateDestination = async (value: string, type: SourceType) => {
    // Try parse number
    try {
      const num = parseEther(value)

      if (num.eq(0)) {
        setDestinationInput('0')
        return
      }

      const amountWsi = await pancakeRouter.getAmountOut(type, num)
      const formattedAmount = formatEther(amountWsi)
      setDestinationInput(
        formattedAmount.length >= 15 ?
          formattedAmount.substring(0, formattedAmount.length - 15) :
          formattedAmount
      )

      return formattedAmount.substring(0, formattedAmount.length - 15)
    } catch (err) {
      // Do nothing
    }
  }

  const calculateSource = async (value: string) => {
    // Try parse number
    try {
      const num = parseEther(value)

      if (num.eq(0)) {
        setSourceInput('0')
        return
      }

      const amountSource = await pancakeRouter.getAmountOut(sourceType, num, true)
      const formattedAmount = formatEther(amountSource)
      setSourceInput(
        formattedAmount.length >= 15 ?
          formattedAmount.substring(0, formattedAmount.length - 15) :
          formattedAmount
      )

      return formattedAmount.substring(0, formattedAmount.length - 15)
    } catch (err) {
      // Do nothing
    }
  }

  const onSourceTypeChange = (option: any) => {
    setSourceType(option.value)
    calculateDestination(sourceInput, option.value)
    validateSource(sourceInput, option.value)
  }

  const approveToken = async () => {
    const token = getTokenForSourceType(sourceType)
    if (!token) {
      return
    }

    await toast.promise(
      token.approve(CONTRACTS.PANCAKE_ROUTER, MAX_UINT_256),
      {
        pending: `Awaiting ${sourceType} approval...`,
        error: `Failed to approve ${sourceType}`,
        success: `Successfully approved ${sourceType}`
      }
    )
  }

  const checkTokenAllowance = (): boolean => {
    const token = getTokenForSourceType(sourceType)
    if (!token) {
      return false
    }

    return token.pancakeRouterAllowance.gte(MAX_UINT_256)
  }

  const onApproveToken = () => {
    if (!isValidSource) {
      return
    }

    // For ERC20 token, approve first before buy
    approveToken()
  }

  const onBuyToken = async () => {
    if (!isValidSource || !isValidSlippage) {
      return
    }

    if (sourceType !== SourceType.BNB && !checkTokenAllowance()) {
      return
    }

    const slippage = BigNumber.from(
      parseFloat(slippageInput) * 100
    )
    const slippageAmount = parseEther(destinationInput).mul(slippage).div(10000)

    await toast.promise(
      pancakeRouter.swapToken(sourceType, parseEther(sourceInput), parseEther(destinationInput), slippageAmount),
      {
        pending: 'Buying WSI...',
        error: 'Failed to buy WSI',
        success: 'Successfully bought WSI'
      }
    )
  }

  return (
    <Layout title='WeSendit - Token Dashboard'
      showFooter
      showHero
      faqEntries={[
        'presale-wallet',
        'presale-walletconnect',
        'presale-contract',
        'presale-audit',
        'presale-vesting',
        'presale-claiming',
        'presale-funding',
        'presale-team-vesting',
        'presale-whitelist'
      ]}>
      <div className='flex max-lg:justify-center max-lg:py-4 gap-4'>
        <LinkComponent href="/vesting">
          <Button size={ButtonSize.SMALL}>
            Vesting Dashboard
          </Button>
        </LinkComponent>
        <Button size={ButtonSize.SMALL} onClick={() => account ? doDisconnect() : doConnect()}>
          {account ?
            EtherUtils.truncateAddress(account)
            :
            t('buttons.connect')
          }
          {account && <MdExitToApp className='text-xl ml-1' />}
        </Button>
      </div>
      <Card classnames='lg:justify-self-start w-full lg:w-11/12'>
        <span className='flex flex-wrap lg:flex-nowrap lg:whitespace-nowrap justify-center items-center text-2xl lg:text-[26px] font-light text-primary self-center'>
          <Trans t={t} i18nKey={countdown.isBeforeStart ? `card.title_before_listing` : `card.title_after_listing`} ns={['index']} components={{
            'pancakeswap-logo': <img className='w-16' src='/assets/img/pancakeswap-logo.png' />
          }} />
        </span>
        <div className='w-full flex flex-col lg:flex-row gap-2'>
          {countdown.isBeforeStart ?
            <div className='w-full lg:h-28 grid grid-cols-4 gap-1 lg:gap-2'>
              <CountdownTile mode={CountdownTileMode.DAYS} value={countdown.tiles[0]} />
              <CountdownTile mode={CountdownTileMode.HOURS} value={countdown.tiles[1]} />
              <CountdownTile mode={CountdownTileMode.MINUTES} value={countdown.tiles[2]} />
              <CountdownTile mode={CountdownTileMode.SECONDS} value={countdown.tiles[3]} />
            </div>
            :
            <div className='w-full lg:h-28 grid grid-cols-2 gap-1 lg:gap-2'>
              <Tile className='h-full' value='Public Sale' label='Stage' />
              <Tile className='h-full' value={`$${EtherUtils.formatEther(pancakeRouter.usdPrice, 3)}`} label={t('card.price_per_wsi', {
                ns: 'index'
              })} />
            </div>
          }
        </div>

        <div className='flex flex-col gap-1 w-full'>
          <div className='flex flex-col lg:flex-row justify-center items-center gap-2 lg:gap-4'>
            <Input disabled={countdown.isBeforeStart} options={TOKEN_OPTIONS} placeholder='0' isValid={isValidSource} value={sourceInput} onChange={onInputSource} onDropdownChange={onSourceTypeChange} />
            <div>
              <MdArrowDropDown className='block lg:hidden text-4xl text-primary' />
              <MdArrowRight className='hidden lg:block text-4xl text-primary' />
            </div>
            <Input disabled={countdown.isBeforeStart} label='$WSI' placeholder='0' value={destinationInput} onChange={onInputDestination} />
          </div>
          <div className='flex w-full justify-between items-center font-bold text-xs lg:text-sm hover:text-primary transition-all'>
            <span className='cursor-pointer' onClick={onClickBalance}>
              {t('balance')}: {getBalance()}
            </span>
            {metamask.isMetamask &&
              <div className='flex justify-center items-center gap-1 cursor-pointer' onClick={metamask.addWesenditToken}>
                <span>
                  {t('buttons.add_to_metamask')}
                </span>
                <MdAdd />
              </div>
            }
          </div>
        </div>

        <div className='flex flex-col lg:flex-row w-full gap-8 justify-between items-center'>
          <span className='text-xs w-full text-left font-bold'>
            {t('card.notice_pancakeswap', {
              ns: 'index'
            })}
          </span>
          <Input className='w-60 place-self-center' label='% Slippage' isValid={isValidSlippage} placeholder='1' max={10} value={slippageInput} onChange={onInputSlippage} />
        </div>

        <div className={classNames(
          'grid grid-cols-1 w-max gap-2',
          {
            'lg:grid-cols-2': account
          }
        )}>
          {account ?
            <>
              <Button size={ButtonSize.MEDIUM} disabled={countdown.isBeforeStart || sourceType === SourceType.BNB || (sourceType === SourceType.BUSD && checkTokenAllowance())} className='w-52' onClick={onApproveToken}>
                {t('buttons.approve')}
              </Button>
              <Button size={ButtonSize.MEDIUM} disabled={countdown.isBeforeStart || !isValidSource || !isValidSlippage || (sourceType === SourceType.BUSD && !checkTokenAllowance())} className='w-52' onClick={onBuyToken}>
                {t('buttons.buy')}
              </Button>
            </>
            :
            <Button size={ButtonSize.MEDIUM} onClick={doConnect}>
              {t('buttons.connect')}
            </Button>
          }
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 place-items-between'>
          <div className='flex flex-col gap-1 justify-center items-center lg:items-start text-center lg:text-left text-[11px]'>
            <a href='https://www.dextools.io/app/en/bnb/pair-explorer/0x84773B2b98613c88834B450af42AEC37A232dB4F' target='_blank' rel="noreferrer">
              <div className='flex gap-2 justify-center items-center cursor-pointer'>
                <MdOpenInNew />
                <span>Price Chart</span>
              </div>
            </a>
            <a href='https://www.bscscan.com/token/0x837A130aED114300Bab4f9f1F4f500682f7efd48' target='_blank' rel="noreferrer">
              <div className='flex gap-2 justify-center items-center cursor-pointer'>
                <MdOpenInNew />
                <span>Token Contract</span>
              </div>
            </a>
          </div>

          <div className='flex flex-col gap-1 justify-center items-center lg:items-end text-center lg:text-right text-[11px]'>
            <a href='https://coinmarketcap.com/currencies/wesendit/' target='_blank' rel="noreferrer">
              <div className='flex gap-2 justify-center items-center cursor-pointer'>
                <MdOpenInNew />
                <span>CoinMarketCap</span>
              </div>
            </a>
            <a href='https://github.com/wesenditmedia/contracts/blob/main/WeSendit_SCAudit_Report_Hacken_io.pdf' target='_blank' rel="noreferrer">
              <div className='flex gap-2 justify-center items-center cursor-pointer'>
                <MdOpenInNew />
                <span>Security Audit 10/10</span>
              </div>
            </a>
          </div>
        </div>
      </Card >
    </Layout >
  )
}
