import { BigNumber } from "ethers"
import { useState, useEffect } from "react"
import { useErc20TokenContract, useAccount, CONTRACTS } from "./useContract"
import { useRefresh } from "./useRefresh"
import { isAddress } from "ethers/lib/utils"

export interface ERC20Token {
  approve: (spender: string, amount: BigNumber) => Promise<void>,
  getAllowance: (address: string, spender: string) => Promise<BigNumber>,
  pancakeRouterAllowance: BigNumber,
  stakingAllowance: BigNumber,
  getBalance: (address: string) => Promise<BigNumber>,
  balance: BigNumber
}

export const MAX_UINT_256 = BigNumber.from('115792089237316195423570985008687907853269984665640564039457584007913129639935')

export const useErc20Token = (address: string): ERC20Token => {
  const contract = useErc20TokenContract(address)
  const {
    account,
    signer
  } = useAccount()
  const {
    doManualRefresh,
    shouldManualRefresh,
    shouldRefresh
  } = useRefresh(2000)

  const [pancakeRouterAllowance, setPancakeRouterAllowance] = useState<BigNumber>(BigNumber.from(0))
  const [stakingAllowance, setStakingAllowance] = useState<BigNumber>(BigNumber.from(0))
  const [balance, setBalance] = useState<BigNumber>(BigNumber.from(0))

  useEffect(() => {
    if (!account || !address || !isAddress(account) || !isAddress(address)) {
      return
    }

    getAllowance(account, CONTRACTS.PANCAKE_ROUTER).then(setPancakeRouterAllowance)
    getAllowance(account, CONTRACTS.STAKING_POOL_ADDRESS).then(setStakingAllowance)
    getBalance(account).then(setBalance)
  }, [account, address, CONTRACTS.PANCAKE_ROUTER, shouldRefresh, shouldManualRefresh])

  const approve = async (spender: string, amount: BigNumber) => {
    if (!signer) {
      throw new Error('Missing Signer')
    }

    const gasLimit = await contract
      .connect(signer)
      .estimateGas
      .approve(spender, amount)

    const tx = await contract.connect(signer).approve(spender, amount, {
      gasLimit: gasLimit.toNumber()
    })

    await tx.wait()

    doManualRefresh()
  }

  const getAllowance = async (address: string, spender: string) => {
    return await contract.allowance(address, spender)
  }

  const getBalance = async (address: string) => {
    return await contract.balanceOf(address)
  }

  return {
    approve,
    getAllowance,
    pancakeRouterAllowance,
    stakingAllowance,
    getBalance,
    balance
  }
}