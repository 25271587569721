import classNames from 'classnames'
import dynamic from 'next/dynamic'
import { ChangeEventHandler } from 'react'
import { OptionsOrGroups, StylesConfig } from "react-select"
const Select = dynamic(() => import('react-select'), { ssr: false })

interface Option {
  label: string,
  image?: string,
  value: string
}

interface Props {
  placeholder: string,
  className?: string,
  value?: string,
  max?: number,
  label?: string,
  options?: Option[],
  onChange?: ChangeEventHandler<HTMLInputElement>,
  isValid?: boolean,
  disabled?: boolean,
  onDropdownChange?: (newValue: any) => void
}

const InputDropdownOption = ({ innerProps, innerRef, label, data }: any) => {
  return (
    <div className='flex gap-2 justify-around items-center py-2 font-bold cursor-pointer transition-all hover:bg-primary hover:text-white' ref={innerRef} {...innerProps}>
      <img className='w-5 h-5' src={data.image} alt={`${label} Logo`} />
      <span>{label}</span>
    </div>
  )
}

const Input = (props: Props) => {
  const { placeholder, className, value, max, label, options, onChange, onDropdownChange, isValid, disabled } = props

  const mapSelectOptions = (options: Option[]): OptionsOrGroups<any, any> => {
    return options.map(option => ({
      label: option.label,
      value: option.value,
      image: option.image
    }))
  }

  const dropdownStyles: StylesConfig<any, false, any> = {
    control: (provided: any, state: any) => {
      return {
        ...provided,
        width: 110,
        outline: 'none',
        border: 'none',
        boxShadow: 'none',
        ":hover": {
          ...provided[':hover'],
          border: 'none',
          boxShadow: 'none'
        },
        ":focus": {
          ...provided[':focus'],
          border: 'none',
          boxShadow: 'none'
        },
        ":active": {
          ...provided[':active'],
          border: 'none',
          boxShadow: 'none'
        }
      }
    },
    singleValue: (provided: any, state: any) => {
      return {
        ...provided,
        fontWeight: 'bold',
        color: '#023662'
      }
    }
  }

  return (
    <div className={classNames(
      'flex justify-between items-center w-full h-12 bg-white border-2 border-primary px-4 py-2 text-base lg:text-base gap-2 text-primary transition-all',
      {
        'border-red-600': isValid !== undefined && !isValid
      },
      className
    )}>
      <input disabled={disabled} className='w-full bg-white focus:outline-none text-right' type="number" min={0} max={max} placeholder={placeholder} value={value} onChange={onChange} />
      {label && <span className="font-bold w-max text-right whitespace-nowrap">{label}</span>}
      {options &&
        <Select components={{
          Option: InputDropdownOption,
        }} defaultValue={mapSelectOptions(options)[0]} options={options} styles={dropdownStyles} isSearchable={false} autoFocus={false} onChange={onDropdownChange} />
      }
    </div>
  )
}

export default Input