import { useCallback, useEffect, useState } from "react";
import { CONTRACTS, useAccount, usePancakeRouterContract } from "./useContract";
import { useRefresh } from "./useRefresh";
import { formatEther, isAddress, parseEther } from "ethers/lib/utils";
import { SourceType } from "./useCrowdsale";
import { BigNumber } from "ethers";
import moment from "moment";

export const usePancakeRouter = () => {
  const contract = usePancakeRouterContract(CONTRACTS.PANCAKE_ROUTER);
  const { account, signer } = useAccount();
  const { shouldRefresh } = useRefresh(5000);

  const [usdPrice, setUsdPrice] = useState(BigNumber.from(0));

  useEffect(() => {
    getAmountOut(SourceType.BUSD, parseEther("1"), true).then(setUsdPrice);
  }, [CONTRACTS.PANCAKE_ROUTER, shouldRefresh]);

  const buildPath = useCallback(
    async (type: SourceType, fromWsi?: boolean): Promise<string[]> => {
      const path: string[] = [];

      if (type === SourceType.BNB) {
        const weth = await contract.WETH();
        path.push(weth);
      } else if (type === SourceType.BUSD) {
        const weth = await contract.WETH();
        path.push(CONTRACTS.BUSD_TOKEN_ADDRESS);
        path.push(weth)
      }

      path.push(CONTRACTS.WSI_TOKEN_ADDRESS);

      if (fromWsi) {
        path.reverse();
      }

      if (path.length > 3) {
        throw Error("Bad swap pair");
      }

      return path;
    },
    [contract]
  );

  const getAmountOut = useCallback(
    async (
      type: SourceType,
      amount: BigNumber,
      fromWsi?: boolean
    ): Promise<BigNumber> => {
      try {
        const path = await buildPath(type, fromWsi);
        const amounts = await contract.getAmountsOut(amount, path);

        return amounts[amounts.length - 1];
      } catch (err) {
        return BigNumber.from(0);
      }
    },
    [buildPath, contract]
  );

  const swapToken = useCallback(
    async (
      type: SourceType,
      amountIn: BigNumber,
      amountOut: BigNumber,
      amountSlippage: BigNumber
    ): Promise<void> => {
      if (!account || !isAddress(account) || !signer) {
        throw new Error("No account");
      }

      const path = await buildPath(type);
      const WETH = await contract.WETH();
      const amountOutMin = amountOut.sub(amountSlippage);
      const deadline = moment().add("1", "minute").unix();

      if (path[0] === WETH || path[path.length - 1] === WETH) {
        // Token for token
        const gasLimit = await contract
          .connect(signer)
          .estimateGas
          .swapExactETHForTokensSupportingFeeOnTransferTokens(
            amountOutMin,
            path,
            account,
            deadline,
            {
              value: amountIn,
            }
          )

        const tx = await contract
          .connect(signer)
          .swapExactETHForTokensSupportingFeeOnTransferTokens(
            amountOutMin,
            path,
            account,
            deadline,
            {
              value: amountIn,
              gasLimit: gasLimit.toNumber()
            }
          );

        await tx.wait();
      } else {
        // BNB for token
        const gasLimit = await contract
          .connect(signer)
          .estimateGas
          .swapExactTokensForTokensSupportingFeeOnTransferTokens(
            amountIn,
            amountOutMin,
            path,
            account,
            deadline
          );

        const tx = await contract
          .connect(signer)
          .swapExactTokensForTokensSupportingFeeOnTransferTokens(
            amountIn,
            amountOutMin,
            path,
            account,
            deadline,
            {
              gasLimit: gasLimit.toNumber(),
            }
          );

        await tx.wait();
      }
    },
    [account, buildPath, contract, signer]
  );

  return {
    getAmountOut,
    swapToken,
    usdPrice,
  };
};
