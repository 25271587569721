import classNames from "classnames"

export interface TileProps {
  value: string
  label: string
  className?: string
}

const Tile = (props: TileProps) => {
  const { value, label, className } = props

  return (
    <div className={classNames(
      'flex flex-col justify-center h-max items-center w-full bg-primary text-white font-light py-2 lg:py-4',
      className
    )}>
      <span className='text-xl lg:text-4xl break-all'>{value}</span>
      <span className='text-xs lg:text-base'>
        {label}
      </span>
    </div>
  )
}

export default Tile